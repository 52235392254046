* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
}



@font-face {
  font-family: "Inter";
  src: url('../src/font/Inter/static/Inter_18pt-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}




@font-face {
  font-family: "helvetica";
  src: url('../src/font/helvetica-compressed-5871d14b6903a.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: "helvetica";
  src: url('../src/font/helvetica-compressed-5871d14b6903a.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
body {
  font-family: "Inter";
  background: linear-gradient(242deg, #FDFEFE -2.37%, #E0EAEF 106.48%);
}





.react-tel-input .form-control {
  position: relative;
  font-size: 14px;
  letter-spacing: .01rem;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 48px;
  margin-left: 0;
  background: none !important;  
   border: none !important; 
   border-radius: 5px;
  line-height: 25px;
  height: 35px;
  width: 277px !important;
   outline: none; 
  border-bottom: 0px;
}


.Header {
  color: #fff;
  width: 100%;
  background: linear-gradient(242.42deg, #FDFEFE -2.37%, #E0EAEF 106.48%);
  border-bottom: 1px solid #A5B2C2;

}

.Main--section--first ul {
    width: 100%;
    padding-left: 18px;
}
.Header--container {
  width: 100%;
  padding: 30px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.main_first {
  display: flex;
  width: 75%;
  align-items: center;
  justify-content: space-between;
}

.header--logo {
  width: 15%;
  display: flex;
  gap: 7px;
  align-items: center;
}

.header--logo img {
  width: 100%;
  height: auto;
}

.header--logo h3 {
  font-family: "Inter";
  font-size: 18px;
  margin: 0px;
  font-weight: 600;
  text-align: left;
  color: #000000;

}
.container {
  width: 100%;
  height: 100vh;
  max-width: 1310px;
  margin: 0 auto;
}
.Header--span {
  display: flex;
  width:  66%;
}

.Header--span ul {
  width: 100%;
  list-style: none;
  display: flex;
  align-items: center;
  gap: 45px;
  justify-content: center;

}

.Header--span ul li a {
  list-style: none;

  font-family: "Inter";
  font-size: 20px;
  font-weight: 400;
  line-height: 19.36px;
  text-align: left;
  color: #000000;
  text-decoration: none;

}

.main_end button {
  padding: 12px 25px;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  text-align: justified;
  color: #E0EAEF;
  background: #101720;
  border-radius: 10px;
}


.Hero--Section {
  width: 100%;
}

.Hero--Section img {
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
}

/* .Main--section {
  width: 100%;
  padding: 30px 0px;
} */

.Main--section {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Main--container {
  width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between; 
}

.Main--section--first {
  width: 32%;

}


.Main--section--first p {
  font-family: "Inter";
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  padding-bottom: 16px;
}

.Main--section--first h2 {
  font-family: "Inter";
  font-size: 32px;
  font-weight: 500;
  line-height: 32px;
  text-align: left;
  padding-bottom: 16px;
}


.Main--section--first h6 {
  font-family: "Inter";
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  padding-bottom: 16px;

}

.Main--section--first ul li span {
  font-family: "Inter";
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  font-weight: bolder;
}

.Main--section--first ul li {
  font-family: "Inter";
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  text-align: left;
  padding-bottom: 24px;
}

.heelo {
  width: 40%;
}


.heelo img {
  width: 100%;
}

.heelo--confrence{
  width: 60%;
  margin-bottom: 40em;
}
.heelo--confrence3{
  width: 60%;
  margin-bottom: 53em;
}
.Main--section--last {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;



}

.Main--section--Qualification--button-box {
  width: 100%;
  padding-top: 85px;
}
.Main--section--last--main {
  width: 48%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 24px;
}
.icon-my-box {
  display: flex;
  align-items: center;
  gap: 24px;
  width: 55%;
}

.icon-my-box img {
  width: 22%;
}
.Main--section--last--part1 {
  width: 100%;
}

.Main--section--last--part1 h3 {
  font-family: "Inter";
  font-size: 18px;
  font-weight: 500;
  line-height: 14.8px;
  text-align: left;
  padding: 0px 0px 16px 35px;
  margin: 0px;
}

.Main--section--last--part1--box {
border: 0.96px solid #101720;
    border-radius: 20px;
    padding: 24px 8px 24px 28px;
    margin: 0px;
    cursor: pointer;
}
.Main--section--last--part1--box.flex-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}


.Main--section--last--part1--box h4 {
  color: #000000;
  font-family: "Inter";
  font-size: 27.76px;
  font-weight: 500;
  line-height: 29.61px;
  text-align: left;
  padding: 0px 0px 5px 0px;
}

.Main--section--last--part1--box h5 {
  font-family: "Inter";
  font-size: 18.51px;
  font-weight: 300;
  line-height: 35.16px;
  text-align: left;
  padding-bottom: 8px;
}




.Main--section--last--part1--box--2 {
  border: 0.96px solid #101720;
  border-radius: 10px;
  padding: 20px 40px;
  display: flex;
  align-items: center;
  gap: 25px;
}
.Main--section--last--part1--box ul {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
}

.Main--section--last--part1--box ul li {
  color: #000;
  font-family: 'Inter';
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
}

.Main--container {}
.Main--section--last--part1--box--2 ul li {
  padding-bottom: 10px;
}

.Main--section--last--part1--2 h3 {
  padding: 0px 10px 10px 10px;
}

.Main--section--last--part2 {

  width: 40%;
  display: flex;
  align-items: center;
  gap: 40px;
}



.Main--section--last--part1--box--online {
  border: 0.96px solid #101720;
  border-radius: 10px;
  padding: 25px 50px;
  margin: 0px 0px 24px 0px
}

.Main--section--last--part1--box--online h4 {
  color: #000000;
  font-family: "Inter";
  font-size: 27.76px;
  font-weight: 600;
  line-height: 29.61px;
  text-align: left;
  padding: 0px 0px 5px 0px;
}

.Main--section--last--part1--box--online h5 {
  font-family: "Inter";
  font-size: 18.51px;
  font-weight: 300;
  line-height: 35.16px;
  text-align: left;
  padding-bottom: 8px;
}

.helol {

  width: 60%;


}

.Main--section--last--part1--button {
  color: #FFFFFF;
  font-family: "Inter";
  font-size: 24px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  background: #101720;
  margin: 40px 0px 0px 0px;
  padding: 23px 25px;
  border-radius: 10px;
  width: 95%;
  cursor: pointer;
}


.Main--section--form--Qualification{
  
      width: 100%;
      padding: 100px 0px 44px 0px;
  
}
.Main--section--Qualification {
  width: 50%;
 
}

.Main--section--Qualification h2 {
  font-family: "Inter";
    font-size: 36px;
    font-weight: 500;
    line-height: 32px;
    text-align: left;
    padding-bottom: 16px;
    border-bottom: 1px solid #A5B2C2;

}

.Main--section--Qualification hr {
  width: 41%;
  border: 1px solid #A5B2C2;
  margin: 0px;
  margin-bottom: 50px;
}

.Main--section--Qualification h6 {
  font-family: "Inter";
  font-size: 17px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #000000;
  padding-top: 60px;
  padding-bottom: 24px;
}

.Qualification--check--main {
  display: flex;
  align-items: center;
  gap: 50px;
}

.Qualification--checkbox {
  display: flex;
  align-items: center;
  gap: 10px;
}

.Qualification--checkbox p {
  color: #000;
  text-align: justify;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}
.Main--section--last--part1--box ul {
  padding-left: 18px;
}
.Qualification--checkbox span {
  font-weight: bolder;
}
.Qualification--checkbox input{ 
    font-size: 16px;
    padding: 10px;
    border: none;
    border-bottom: 2px solid #A5B2C2;
    background-color: transparent;
    width: 24px;
    height: 24px; 
}
.Ticket--type--checkbox input{ 
    font-size: 16px;
    padding: 10px;
    border: none;
    border-bottom: 2px solid #A5B2C2;
    background-color: transparent;
    width: 24px;
    height: 24px; 
}
.Main--section--Qualification--button {
  font-family: "Inter";
  font-size: 18px;
  font-weight: 500;
  line-height: 13px;
  text-align: center;
  color: #FFFFFF;
  background: #101720;
  padding: 18px 30px;
  border-radius: 6px;
  width: 80%;
}


.Main--section--last--part1--currency {
  display: flex;
  align-items: center;
  gap: 25px;
  padding-top: 25px;
}

.Main--section--last--part1--currency p {
  color: #A5B2C2;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 100% */

}


footer {
  width: 100%;
  margin-top: 33px;
}

.footer--main {
  width: 100%;
  background: #101720;
  padding: 48px 65px;
}

.footer--top {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.footer--logo {
  display: flex;
  align-items: center;
  width: 25%;
  gap: 10px;
}

.footer--logo h3 {
  color: #FFFFFF;
  font-family: "Inter";
  font-size: 32.9px;
  font-weight: 400;
  line-height: 30.9px;
  text-align: left;
  margin: 0px;
}


.footer--email {
  width: 22%;
}

.footer--email input[type="text"] {
  border: 1px solid #E0EAEF;
  background: none;
  padding: 10px 35px;
  color: #FFF;
}

.footer--email ::placeholder {
  color: #A5B2C2;
  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;

}

.footer--email button {
  margin-top: 15px;
  color: #101720;
  font-family: "Inter";
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  padding: 8px 20px;
  width: 96%;
  text-align: center;
}

.footer--main hr {
  background: #E0EAEF;
  height: 3px;
}



.footer--bottom {
  display: flex;
  align-items: center;
  width: 100%;
  padding-top: 41px;
}

.footer--location {
  width: 26%;
  display: flex;
  align-items: flex-start;
  gap: 10px;

}

.footer--location img {
  width: 7%;
}

.footer--location--text {
  margin: 0px;
}

.footer--location--text h4 {
  color: #FFFFFF;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
}

.footer--location--text p {
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: #515151;
}

.footer--conditions {
  display: flex;
  align-items: center;
  width: 55%;
  gap: 90px;
}

.footer--conditions h4 {
  color: #FFFFFF;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
}

.footer--Icons {
  width: 10%;
  display: flex;
  align-items: center;
  gap: 20px;
}



.Main--section--Confrence {
width: 50%;
}

.onlyforborder{
  border-right: 1px solid #000;
  padding: 0px 50px;
}

.Main--section--Confrence h2 {
  font-family: "Inter";
  font-size: 36px;
  font-weight: 500;
  line-height: 32px;
  text-align: left;
  padding-bottom: 10px;

}

.Main--section--Confrence hr {
  width: 49%;
  border: 1px solid #A5B2C2;
  margin: 0px;
  margin-bottom: 50px;
}


.Confrence--Ticket--info {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.Ticket--info--navigation {
  display: flex;
  align-items: center;
  gap: 7px;
  padding-bottom: 10px;
}

.Ticket--info--navigation input[type="text"] {
  border: transparent;
  font-family: "Inter";
  font-size: 18px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: #A5B2C2;
}
.Confrence--Ticket h5 {
  font-family: "Inter";
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #000000;
}

.Ticket--info--number {
  display: flex;
  align-items: center;
  gap: 7px;
  padding-bottom: 10px;
}

.Ticket--info--zipcode {
  display: flex;
  align-items: center;
  gap: 7px;
  padding-bottom: 10px;
}

.Ticket--info--zipcode img{
  width: 5%;
}


.Ticket--info--number input[type="number"] {
  border: transparent;
  font-family: "Inter";
  font-size: 18px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: #A5B2C2;
}

.Ticket--info--zipcode input[type="text"] {
  border: transparent;
  font-family: "Inter";
  font-size: 18px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: #A5B2C2;
}


.Confrence--Ticket--addmore button {
  border-radius: 15px;
  border: 1px dashed #101720;
 
  font-family: "Inter";
  font-size: 18px;
  font-weight: 500;
  line-height: 32px;
  text-align: left;
  padding: 17px 20px;
  width: 60%;
  text-align: center;
  background: #A5B2C2;
}



.info--navigation--hr hr {
  width: 82%;
}
.info--navigation--hr {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 48px;
  gap: 20px;
}

.Ticket--info--number {
  width: 48%;
  border-bottom: 1px solid #A5B2C2;
}

.Ticket--info--navigation {
  width: 48%;
  border-bottom: 1px solid #A5B2C2;
}

.Ticket--info--zipcode {
  border-bottom: 1px solid #A5B2C2;
  width: 48%;
}

.Ticket--info--number img {
  width: 8%;
}

.Confrence--Ticket--addmore {
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.Confrence--Ticket--type h5 {
  font-family: "Inter";
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #000000;
  margin-bottom: 10px;
}

.Ticket--type--checkbox {
  display: flex;
  align-items: center;
  gap: 34px;
}

.Confrence--Ticket--type--Person {  
  display: flex;
  align-items: center;
  gap: 10px;
}
.Confrence--Ticket--type hr {
  margin-bottom: 30px;
  width: 17%;
}



.Confrence--Ticket--type {
  margin: 34px 0px;
}

.Confrence--Ticket--type--credit p{
  font-family: "Inter";
  font-size: 16px;
  font-weight: 500;
  line-height: 15.14px;
  text-align: left;
  color: #E0EAEF;
}

.Confrence--Ticket--type--credit img{
  width: 20%;
}

.Confrence--Ticket--type--credit {
  display: flex;
  width: 30%;
  align-items: center;
  gap: 10px;
  background: #101720;
  margin-bottom: 30px;
  border-radius: 10px;
  padding: 15px 10px;
}

.Confrence--Ticket--type--card {
  display: flex;
  align-items: center;
  gap: 30px;
  width: 60%;
  margin-bottom: 15px;
}

.Confrence--Ticket--type--card input[type="text"] {
  border: none;
  font-size: 25px;
}


.Confrence--Ticket--type--card input[type="text"]:active {
  border: 1px solid #ccc !important; /* to override */
}

.Confrence--Ticket--type--card ::placeholder{
  color: #A5B2C2;
  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  
}

.card--input hr{
  width: 100%;
}



.Confrence--Ticket--type--card--info {
  display: flex;
  align-items: center;
  gap: 15px;
  width: 40%;
  margin-bottom: 50px;
}

.Confrence--Ticket--type--card--info input[type="text"] {
  border: none;
  font-size: 12px;
}

.Confrence--Ticket--type--card--info ::placeholder{
  color: #A5B2C2;
  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  
}

.Confrence--Ticket--type h3 {
  font-family: "Inter";
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: #000000;
  padding-top: 15px;

}

.payment--condiotions {
  margin-top: 20px;
  width: 70%;
}

.payment--condiotions h4 {
  font-family: "Inter";
font-size: 18px;
font-weight: 600;
line-height: 14.52px;
text-align: left;
color: #000000;

}


.payment--condiotions p {
  padding-top: 12px;
  font-family: "Inter";
font-size: 16px;
font-weight: 300;
line-height: 22.52px;
text-align: left;

}
.Main--section--Confrence--last {
  width: 100%;
  padding: 77px 35px;
  border: 1px solid #000000;
  border-radius: 20px;
  text-align: center;
}

.Main--section--Confrence--last h2{
  font-family: "Inter";
font-size: 32px;
font-weight: 500;
line-height: 40px;
text-align: center;

}
.Main--section--Confrence--last img {
  padding-bottom: 30px;
}

.Main--section--Confrence--last p {
  padding-top: 20px;
  text-align: center;
}
.heelo-2 {
  width: 65%;
}

.Main--section--Confrence--last--button button{
  font-family: "Inter";
font-size: 18px;
font-weight: 500;
line-height: 13px;
text-align: center;
padding: 19px 0px;
width: 100%;
background: #101720;
color: #FFF;
margin-top: 67px;
}


.Main--container--bootstrap{
  width: 100%;
  display: flex;
  align-items: center;
  gap: 0px;
  justify-content: space-between;
}
.payment-form {
  max-width: 500px;
  padding: 20px 20px 20px 0px;
  font-family: Arial, sans-serif;
  color: #333;
}
h3 {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 10px;
}
.form-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-bottom: 20px;
}
.form-group {
  display: flex;
  flex-direction: column;
}
label {
  font-size: 0.9rem;
  color: #555;
  margin-bottom: 5px;
}
input,
.StripeElement {
  font-size: 16px;
  padding: 10px;
  border: none;
  border-bottom: 2px solid #A5B2C2;
  background-color: transparent;
}
.StripeElement--focus,
input:focus {
  border-bottom-color: #424770;
  outline: none;
}
button {
  width: 100%;
  padding: 10px;
  background-color: #0070F3;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 1rem;
}
button:disabled {
  background-color: #aaa;
  cursor: not-allowed;
}
.error-message {
  color: #FF0000;
  font-size: 0.9rem;
  margin-top: 10px;
}
.success-message {
  color: #4CAF50;
  font-size: 0.9rem;
  margin-top: 10px;
}


.main-web-5 {
  width: 100%;
  padding:0px;
}

.web-5-heading {
  width: 100%;
  padding-bottom: 62px;
}

.web-5-heading h2 {
  color: #201A1E;
  font-family: "Tobias TRIAL";
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px; /* 100% */
}

.web-5-heading p {
  color: #6F6F6F;
  font-family: "Cerebri Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px; /* 100% */
}

.all-main-web {
  width: 78%;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.web-Personal-box {
  width: 100%;
}

.web-Personal-box h2 {
  color: #201A1E;
  font-family: "Tobias TRIAL";
  font-size: 24px;
  font-style: normal;
  font-weight: 350;
  line-height: 32px; /* 133.333% */
  text-decoration: underline;
}

.Personal-box-input {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 32px;
  gap: 20px;
}



.Personal-box-input input {
  width: 48%;
  padding: 12px 0px !important;
  border-bottom: 1px solid #201A1E;
  background: transparent;
  font-size: 16px;
  outline: none;
}

.Personal-box-input select {
  width: 48%;
  border: none;
  padding: 12px 0px !important;
  border-bottom: 1px solid #201A1E;
  background: transparent;
  font-size: 16px;
  outline: none;
}

.extara-div {
  width: 48%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
}

.extara-div label {
  color: #201A1E;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 100% */
  margin: 0px;
}

.extara-div textarea {
  width: 100%;
  border: 1px solid #201A1E;
  background: transparent;
  height: 24vh;
  padding: 20px;
}

.extara-div input {
  border: 1px solid #201A1E;
  text-align: center;
  width: 100%;
}

.all-main-web button {
  padding: 22px;
  background: #201A1E;
}
.main-web-sec {
  width: 100%;
  background: #EDE3DD;
  height: 100%;
  overflow: auto;
}




.Newpopup-sec {
  width: 100%;
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 999;

}

.Newpopup-popup {
  border-radius: 20px;
  background: #FFF;
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.25);
  width: 630px;
  height: 40vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 40px;
}

.Newpopup-popup svg {
  width: 100px;
  height: 100px;
}

.Newpopup-popup button {width: 200px;border-radius: 14px 0px 14px 0px;padding: 15px; background-color: #EDE3DD; color: #000;}

.Newpopup-popup h2 {
  color: #000;
  text-align: center;
  font-family: sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -1.68px;
}

.Newpopup-popup p {
  text-align: center;
  padding-top: 10px;
  font-size: 18px;
::-webkit-scrollbar {
  width: 0px;
}
}

.containerx{
  width: 100%;
}






@media screen and (min-width: 1920px) {
  html {
    zoom: 1;
}
}
@media screen and (min-width: 2160px) {
  html {
    zoom: 1.4;
}
}
@media screen and (min-width: 2500px) {
  html {
    zoom: 1.7;
}
}
@media screen and (min-width: 3000px) {
  html {
    zoom: 1.9;
}
}
@media screen and (min-width: 3500px) {
  html {
    zoom: 2.3;
}
}
@media screen and (min-width: 4500px) {
  html {
    zoom: 2.6;
}
}
@media screen and (min-width: 4800px) {
  html {
    zoom: 2.8;
}
}
@media screen and (min-width: 5500px) {
  html {
    zoom: 2.8;
}
}
@media screen and (min-width: 7500px) {
  html {
    zoom: 4;
}
}







@media screen and (max-width: 1366px) {
  .container {
    max-width: 100%;
    padding: 0px 4%;
}

.Main--section--first h2 {
    font-size: 30px;
}

.Main--section--last--part1--box ul li {
    line-height: 30px;
}

.Main--section--last--part1 h3 {
    padding: 0px 0px 15px 30px;
}

.Main--section--last--part1--button {
    font-size: 20px;
    margin: 18px 0px;
    padding: 20px 20px;
}

h2 {}.Main--section--Qualification h2 {
  font-size: 32px;
}

.Main--section--Qualification h6 {
  padding-top: 50px;
  padding-bottom: 15px;
}

.Main--section--Qualification--button-box {
  padding-top: 70px;
}

.Main--section--form--Qualification {
  padding: 90px 0px 40px 0px;
}

.Main--section--Confrence h2 {
  font-size: 32px;
}

.Main--section--Confrence hr {
  margin-bottom: 35px;
}

.Ticket--info--number img {
  width: 8%;
}

.info--navigation--hr {
  padding-bottom: 30px;
  gap: 15px 0px;
}

.Confrence--Ticket--addmore button {
  padding: 14px 18px;
  border-radius: 10px;
  width: 50%;
}

.Confrence--Ticket--type {
  margin: 28px 0px;
}

.Confrence--Ticket--type--credit {
  margin-bottom: 25px;
  border-radius: 5px;
  padding: 10px;
  width: 32%;
  text-align: center;
  justify-content: center;
}

.Main--section--Confrence--last {
  padding: 55px 25px;
}

.Main--section--Confrence h2 {
  font-size: 28px;
}

.Main--section--Confrence--last p {
  padding-top: 15px;
}

.Main--section--Confrence--last img {
  width: 18%;
  padding-bottom: 20px;
}

.Main--section--Confrence--last--button button {
  margin-top: 45px;
}


.Qualification--checkbox input{   
  width: 22px;
  height: 24px; 
}
.Ticket--type--checkbox input{ 
  width: 22px;
  height: 24px; 
}


}


@media screen and (max-width: 1024px) {
 
  .Main--section--first h2 {
    font-size: 24px;
    padding-bottom: 10px;
}

.Main--section--first p {
    font-size: 16px;
    padding-bottom: 12px;
}

.Main--section--first h6 {
    font-size: 16px;
    padding-bottom: 12px;
}

.Main--section--first ul li {
    font-size: 14px;
    padding-bottom: 12px;
}

.Main--section--first ul li span {
    font-size: 16px;
}

.Main--section--last--part1--box h5 {
    font-size: 16px;
    line-height: 30px;
}

.Main--section--last--part1--box h4 {
    font-size: 25px;
}

.Main--section--last--part1--box ul li {
    line-height: 25px;
}

.Main--section--last--part1--box.flex-box {
    flex-direction: column;
    gap: 12px;
}

.Main--section--last--part1 h3 {
    font-size: 16px;
    padding: 0px 0px 12px 25px;
}

.Main--section--last--part1--button {
    font-size: 16px;
    margin: 15px 0px;
    padding: 15px 16px;
}

.Main--section--last--part1--box {
    border-radius: 10px;
    padding: 18px 30px;
}


.Main--section--Qualification h2 {
  font-size: 28px;
  padding-bottom: 12px;
}

.Main--section--Qualification h6 {
  padding-top: 40px;
  padding-bottom: 10px;
  font-size: 16px;
}

.Qualification--checkbox p {
  font-size: 12px;
  line-height: 20px;
}

.Main--section--Qualification {
  width: 58%;
}

.heelo {
  width: 38%;
}

.Main--section--Qualification--button-box {
  padding-top: 50px;
}

.Main--section--form--Qualification {
  padding: 70px 0px 30px 0px;
}

.Main--section--Confrence h2 {
  font-size: 24px;
}

.Ticket--info--navigation {
  width: 100%;
}

.Ticket--info--number {
  width: 100%;
}

.Ticket--info--zipcode {
  width: 100%;
}

.Confrence--Ticket--addmore {
  margin-bottom: 30px;
}

.Confrence--Ticket--addmore button {
  padding: 12px 14px;
  border-radius: 7px;
  line-height: 24px;
}

.Confrence--Ticket--type h5 {
  font-size: 16px;
}

.Confrence--Ticket--type--credit {
  width: 40%;
}

.Main--section--Confrence hr {
  margin-bottom: 20px;
}

.Confrence--Ticket--type h3 {
  font-size: 18px;
}

.Main--section--Qualification--button {
  font-size: 16px;
}

.Qualification--checkbox input{   
  width: 20px;
  height: 20px; 
}
.Ticket--type--checkbox input{ 
  width: 20px;
  height: 20px; 
}


}


@media screen and (max-width: 768px) {
  
  .Main--section--Confrence--last {
    padding: 50px 20px;
}


.Main--container--bootstrap {
  width: 100%;
  gap: 50px;
  flex-direction: column;
}
.all-main-web {
  width: 100%;
}

.Main--section--Confrence {
  width: 70%;
}

.heelo {
  width: 70%;
}
  .Main--container {
    flex-direction: column;
    gap: 20px;
}

.Main--section--first {
    width: 100%;
}

.heelo-2 {
    width: 100%;
}
.Main--section--form--Qualification {
  padding: 50px 0px 30px 0px;
}

.Main--section--Qualification {
  width: 100%;
}

.heelo {width: 53%;}

.Main--container {
  align-items: center;
}

.Main--section--Qualification--button-box {
  padding-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Main--section--Confrence {
  width: 75%;
}

.Ticket--info--number img {
  width: 4%;
}


.Qualification--checkbox input{   
  width: 20px;
  height: 20px; 
}
.Ticket--type--checkbox input{ 
  width: 20px;
  height: 20px; 
}


.onlyforborder{
  border: none;
}

}



@media screen and (max-width: 600px) {
  .Main--section--Confrence {
    width: 90%;
}
.heelo {
  width: 90%;
}
  
  .Main--section--first h2 {
    font-size: 20px;
}

.Main--section--first p {
    padding-bottom: 10px;
    font-size: 14px;
}

.Main--section--first h6 {
    font-size: 14px;
    padding-bottom: 10px;
}

.Main--section--first ul li {
    font-size: 12px;
    padding-bottom: 10px;
    line-height: 18px;
}

.Main--section--first ul li span {
    font-size: 14px;
}

.Main--section--last {
    flex-direction: column;
    gap: 20px;
}

.Main--section--last--main {
    width: 100%;
}
.web-5-heading h2 {
  font-size: 25px;
}

.web-5-heading {
  padding-bottom: 35px;
}

.web-Personal-box h2 {
  font-size: 20px;
}

.Personal-box-input {
  padding-top: 20px;
  gap: 15px;
}

.Personal-box-input input {
  width: 100%;
}

.all-main-web {
  gap: 20px;
}

.Personal-box-input select {
  width: 100%;
}

.extara-div textarea {
  width: 100%;
}

.extara-div {
  width: 100%;
}

.all-main-web button {
  padding: 18px;
}
.Main--section--last--part1--box.flex-box {
    flex-direction: row;
}

.Main--section--last--part1--button {
    font-size: 14px;
    margin: 24px 0px 0px 0px;
    padding: 12px 14px;
    border-radius: 8px;
}

.Main--section--last--part1--box h4 {
    font-size: 22px;
}

.Main--section--last--part1--box h5 {
    font-size: 14px;
    line-height: 24px;
}

.Main--section--last--part1--box ul li {line-height: 14px;}

.Main--section--form--Qualification {
  padding: 40px 0px 30px 0px;
}

.Main--section--Qualification h2 {
  font-size: 25px;
  padding-bottom: 10px;
}

.Main--section--Qualification h6 {
  padding-top: 30px;
  padding-bottom: 10px;
  font-size: 14px;
  line-height: 20px;
}

.Qualification--check--main {
  align-items: flex-start;
  gap: 8px;
  flex-direction: column;
}

.Main--section--Qualification--button {
  padding: 16px 30px;
}

.heelo {
  width: 100%;
}

.Main--section--Confrence {
  width: 100%;
}

.Confrence--Ticket h5 {
  font-size: 16px;
}

.Main--section--Confrence h2 {
  font-size: 25px;
}

.Ticket--info--navigation input[type="text"] {
  font-size: 15px;
}

.Ticket--info--number input[type="number"] {
  font-size: 15px;
}

.Ticket--info--navigation {
  padding-bottom: 4px;
}

.Ticket--info--number {
  padding-bottom: 5px;
}

.info--navigation--hr {
  padding-bottom: 25px;
  gap: 12px 0px;
}

.Confrence--Ticket--addmore button {
  padding: 12px 12px;
  border-radius: 4px;
  line-height: 18px;
}

.form-grid {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.form-group {
  width: 100%;
}

.Confrence--Ticket--type h5 {
  margin-bottom: 5px;
}



.Qualification--checkbox input{   
  width: 20px;
  height: 20px; 
}
.Ticket--type--checkbox input{ 
  width: 20px;
  height: 20px; 
}


.onlyforborder{
  border: none;
}
.main-web-5 {
  padding: 0px;
}
.Newpopup-sec {
  padding: 0px 20px;
}

.Newpopup-popup span {
  width: 100%;
}

.Newpopup-popup h2 {
  width: 100%;
  font-size: 28px;
}

.Newpopup-popup svg {
  width: 75px;
  height: 75px;
}

.Newpopup-popup {
  gap: 28px;
  border-radius: 10px;
}

.Newpopup-popup button {
  width: 190px;
  border-radius: 8px 0px 8px 0px;
  padding: 14px;
}
}












